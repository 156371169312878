import React, { useState, useEffect} from 'react';
import { configData } from "../config";
import { Link,useNavigate, useParams } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import './classes/MiscellaneousCharge.css';
import MinicartLength from './MinicartLength';
import ReactDOM from "react-dom/client";
import CartTooltip from './CartTooltip';
import Select from 'react-select';
import Loading from './Loading';
import Swal from 'sweetalert2'

function MiscellaneousCharge() {
    const [formData, setFormData] = useState({
       
        lineitems:[],
        single_lineitems:''
        
      });
      const [addformData, setAddFormData] = useState({
       
        disclaimer_editor:'',
        relate_misc:'',
        amount:''
        
      });
      const data  = useParams();	
   
    const uid = data.uid
    
    const [productlist, setProductList] = useState([]);
    const [selectproductlist, setSelectProductList] = useState({});
    const [isLoading, setIsLoading] = useState(true);
    const [showSuccessMessage, setShowSuccessMessage] = useState(false);
    const [message, setSuccessMessage] = useState([]);
    const [showModal, setShowModal] = useState(false);
    const[error,setError] = useState({});
    const app_url = configData.APP_URL;

    useEffect(() => {
        retrievemiscellaneousproducts();
        retrieveselectmiscellaneousproducts();
        setTimeout(() => {
            setIsLoading(false);
          }, 800);
     }, [])
     
    async function retrievemiscellaneousproducts() {
        const productlistresponse = await fetch(
    app_url+'smwwcommercemiscellaneous/getproductlist',
    {
      method: "GET",

      headers: {
        'Content-Type': 'application/json',
      },
    }
  )
  const productlistdata = await productlistresponse.json();
  const productlist = productlistdata.data.result;
  setProductList(productlist);
     }
     async function retrieveselectmiscellaneousproducts() {
      const productlistresponse = await fetch(
  app_url+'smwwcommercemiscellaneous/getselectproductlist',
  {
    method: "GET",

    headers: {
      'Content-Type': 'application/json',
    },
  }
)
const productlistdata = await productlistresponse.json();
const productlist = productlistdata.data.result;
setSelectProductList(productlist);
   }
     const handleChange = event => {
        const { name, value} = event.target;
        setFormData(prevState => ({
          ...prevState,
          [name]: value,
        }));
      
        if(event.target.name === 'lineitems') {
          const isChecked = event.target.checked;
          if(isChecked){
          setFormData(prevState => ({
            ...prevState,
            lineitems: [...formData.lineitems, event.target.value],
            
          }));
        } else {
          let index = formData.lineitems.indexOf(event.target.value);
          formData.lineitems.splice(index, 1);
          setFormData(prevState => ({
            ...prevState,
            lineitems: formData.lineitems, 
            
          }));
        }
        }
   
    };
    const handleAddChange = event => {
      const { name, value} = event.target;
      setAddFormData(prevState => ({
        ...prevState,
        [name]: value,
      }));
    
  };
    const handleSubmit = async event => {
        event.preventDefault();
       
        if (formData.lineitems.length === 0) {
            alert("Please check any one of the product.");

        } else {
            var count = formData.lineitems.length;
            var selectedOptions = {};
            for (let i = 0; i < count; i++) {
                selectedOptions[formData.lineitems[i]] = 1;
            }
            const postData = {
            lineitems: selectedOptions,
            country:'',
            order_id: localStorage.getItem('order_id'),
            uid:uid
            }
            const response = await fetch(app_url+'smwwcommercepay/addtocart', {
                method: 'POST',
                body: JSON.stringify(postData),
                headers: {
                'Content-Type': 'application/json',
                },
            });
            const data = await response.json();
            const yii_session = data.data.session;
            if(data.data.result === "order_created") {
        
                if(yii_session.order_id) localStorage.setItem('order_id', yii_session.order_id);
                else localStorage.setItem('order_id', data.data.order_id);
                setSuccessMessage(data.data.messages.success);
                setShowSuccessMessage(true);
                const root = ReactDOM.createRoot(document.getElementById("top-header-cart-icon"));
                root.render(<MinicartLength length={data.data.products.length} />);
                
              }
         
        }
      };
      const handleSubmit1 = async event => {
        
            var selectedOptions = {[formData.single_lineitems]:1};
            
            const postData = {
            lineitems: selectedOptions,
            country:'',
            order_id: localStorage.getItem('order_id'),
            uid:uid
            }
            const response = await fetch(app_url+'smwwcommercepay/addtocart', {
                method: 'POST',
                body: JSON.stringify(postData),
                headers: {
                'Content-Type': 'application/json',
                },
            });
            const data = await response.json();
            const yii_session = data.data.session;
            if(data.data.result === "order_created") {
        
                if(yii_session.order_id) localStorage.setItem('order_id', yii_session.order_id);
                else localStorage.setItem('order_id', data.data.order_id);
                setSuccessMessage(data.data.messages.success);
                setShowSuccessMessage(true);
                const root = ReactDOM.createRoot(document.getElementById("top-header-cart-icon"));
                root.render(<MinicartLength length={data.data.products.length} />);
                
              }
      };
      async function addtoCart(sku) {
        var selectedOptions = {[sku]:1};
            
        const postData = {
        lineitems: selectedOptions,
        country:'',
        order_id: localStorage.getItem('order_id'),
        uid:uid
        }
        const response = await fetch(app_url+'smwwcommercepay/addtocart', {
            method: 'POST',
            body: JSON.stringify(postData),
            headers: {
            'Content-Type': 'application/json',
            },
        });
        const data = await response.json();
        const yii_session = data.data.session;
        if(data.data.result === "order_created") {
    
            if(yii_session.order_id) localStorage.setItem('order_id', yii_session.order_id);
            else localStorage.setItem('order_id', data.data.order_id);
            setSuccessMessage(data.data.messages.success);
            setShowSuccessMessage(true);
            const root = ReactDOM.createRoot(document.getElementById("top-header-cart-icon"));
            root.render(<MinicartLength length={data.data.products.length} />);
            
          }
       
    }
      const handleNewMiscProduct = async event => {
        setShowModal(true);
      };
      const handleModalClose = async event => {
        setShowModal(false);
      };
      const validateForm = (data) => {
        const errors = [];
        
        if (!addformData.relate_misc.trim()) {
       
          errors.push("Product is required.");
        }
        if (!addformData.disclaimer_editor.trim()) {
            errors.push("Notes field is required.");
        }
        if (!addformData.amount.trim()) {
          errors.push("Amount is required.");
      }
       
        return errors;
      }
      const handleAddProduct = async event => {
        const newErrors = validateForm(addformData);
        setError(newErrors);
   
        if (Object.keys(newErrors).length === 0) {
          const postData = {
            relate_misc: addformData.relate_misc,
            amount:addformData.amount,
            disclaimer_text:addformData.disclaimer_editor,
            }
            const response = await fetch(app_url+'smwwcommercemiscellaneous/addmiscproduct', {
                method: 'POST',
                body: JSON.stringify(postData),
                headers: {
                'Content-Type': 'application/json',
                },
            });
            const data = await response.json();
            if(data.data.result === "Success") {
              Swal.fire({
                title: "Success!",
                text: "Miscellaneous Product created successfully!",
                type: "success",
                confirmButtonText: "Add to Cart",
                showCancelButton: true,
              })
              .then((result) => {
                if (result.value) {
                  addtoCart(data.data.sku);
                } else if (result.dismiss === 'cancel') {
                  window.location.reload();
                }

              });

            }
          }
   
      };
     return (
        <>
        {isLoading ? (
        <Loading />
      ) : (
            <div id="main">
                <Helmet>
                    <title>SMWW miscellaneous charge</title>
                </Helmet>
                <div className="left"></div>
                <div className="center left-center misc_charge">
                {showSuccessMessage && (
        <div className="success-message">
          <div className="success-message-header"><strong>Added Successfully</strong><span className="close" onClick={() => setShowSuccessMessage(false)}>×</span></div>
          <div className="success-message-content">
          <div className="message-content">
            <div className="message-icon">
              <i className="fa fa-check-circle"></i>
            </div>
            <div className="message-text">
            {message.map((message, index) => (
                 <p>{message}</p>
            ))}
               
            </div>
          </div>
          <div className="success-buttons">
          <Link to="/smww/cart">
            <button className="view-cart-button">View Cart</button>
          </Link> 
          <Link to="/smww/checkout">
            <button className="checkout-button">Checkout</button>
          </Link>   
            <button className="close-button" onClick={() => setShowSuccessMessage(false)}>Close</button>
          </div>
          </div>
        </div>
      )}
        <h3>Miscellaneous Charge</h3>
        <a href="#" onClick={handleNewMiscProduct}>+Add New</a><br/><br/>
        {showModal && (
        <div id="newproduct" className="modal">
          <div className="modal-content">
            <div className="modal-header">
              <span className="close" onClick={handleModalClose}>&times;</span>
              <p><b>Add Miscellaneous Charge</b></p>
            </div>
            <div className="modal-body">
            {error.length > 0 && (
          <div className="set-message-error">
           {error.map((item, index) => {
           return <>{item}<br /></>
          
        })}  
          </div>
        )}
             <form>
             <label htmlFor="add_lineitems">Product:</label>
             <Select id="relate_misc" name="relate_misc" className='relate_misc' 
                      onChange={(val) => handleAddChange({ target: { value: val.value, name: 'relate_misc' } })} options={selectproductlist}>
                    </Select><br/>
              <label htmlFor="amount">Price:</label>
              <input type="text" name="amount" id="amount" onChange={handleAddChange}/><br/><br/>
              <label htmlFor="notes">Notes:</label>
              <textarea id="disclaimer_editor" name="disclaimer_editor" onChange={handleAddChange}></textarea>
              <input type="button" className="quick-links-reg-button" value="Save" onClick={handleAddProduct}></input>
             </form>
            </div>
           
          </div>
        </div>
        )}
                    <form>
                      <div className="row">
                      <div className="col-md-1"><label htmlFor="product">Product:</label></div>
                      <div className="col-md-2"><Select id="single_lineitems" name="single_lineitems" className='select-lineitems' 
                      onChange={(val) => handleChange({ target: { value: val.value, name: 'single_lineitems' } })} options={selectproductlist}>
                    </Select></div><div className="col-md-3"><input type="button" className="quick-links-reg-button" value="Charge" onClick={handleSubmit1}></input></div></div><br/>
                        <table><tr><th></th><th>Amount</th><th>Product</th><th>Notes</th></tr>
                    {productlist.map((course, index) => (
                    <><tr>
                        <td><input type="checkbox" name="lineitems" id={course.product_sku} value={course.product_sku} onChange={handleChange}></input></td>
                        <td>{course.product_name}</td>
                        <td>{course.price}</td>
                        <td>{course.disclaimer_text}</td>
                    </tr></>
                    ))}
                    </table><br/><br/>
                    <input type="button" className="quick-links-reg-button" value="Charge" onClick={handleSubmit}></input>
                     </form>
                </div>
                <div class="right">

<div className="order-details-page-quick-links">
          <h3>Quick Links</h3>
          <ul>
            <li className='header-hello-text'><a className="quick_link_anchor" href={'/user/'+uid}>Manage My Profile</a></li>
            <li>
              <a href="/smww_pay">
              <input type="button" className='quick-links-reg-button' value="Register for a Course"/>
              </a>
            </li>
           
            {localStorage.getItem('masquerade') && (
              localStorage.getItem('masquerade') == 1 && (
                <li>
                  <a className="quick_link_anchor" href={'/miscellaneous/charge/'+uid} >
                   Miscellaneous Charge
                  </a>
                </li>
               ))}
            </ul>
        </div>

</div>
  <div class="clear"></div> 
            </div> 

            )} </>
        );
}
export default MiscellaneousCharge;