import React, { useState, useEffect } from 'react';
import { configData } from "../config";
import './classes/Login.css';
import './classes/Common.css';
import './classes/UserTab.css';
import '@fortawesome/fontawesome-free/css/all.css';
import { Link, useNavigate } from 'react-router-dom';

function Login() {
  const [formData, setFormData] = useState({
    username: '',
    password: '',
    order_id: localStorage.getItem('order_id'),
    rememberMe: '',
  });
  
  const app_url = configData.APP_URL;

  const [errors, setErrors] = useState({});
  const domain_url = configData.DOMAIN_URL;
  const navigate = useNavigate();
  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };
  useEffect(() => {
    
    if(localStorage.getItem('username')) {
      navigate("/user");
    }
  }, []);
  
  const validate = () => {
    let tempErrors = {};
    if (!formData.username) tempErrors.username = 'User Name is required.';
    if (!formData.password) tempErrors.password = 'Password is required.';
   
    setErrors(tempErrors);
   
    return Object.keys(tempErrors).length === 0;
  }; 
    const handleLogin = async event => {
      event.preventDefault();

      if (validate()) {
        const postData = formData; 
        const response = await fetch(app_url+'site/login', {
        method: 'POST',
        body: JSON.stringify(postData),
        headers: {
          'Content-Type': 'application/json',
        },
        });
        const data = await response.json();
        let tempErrors = {};
        const resultdata = data.data;
        if(resultdata.msg === "error") {
          tempErrors.password = data.data.result+'<a href="/user/password">Have you forgotten your password?</a>';
          setErrors(tempErrors);
        } else {
         
          if(resultdata.result.uid > 0) {
            localStorage.setItem('uid', resultdata.result.uid);
            localStorage.setItem('username', resultdata.result.username);
            localStorage.setItem('email', resultdata.result.email);
            localStorage.setItem('drupal_uid', resultdata.result.drupal_uid);
            localStorage.setItem('session_id', resultdata.session_id);
            if(resultdata.order_id > 0) {
              localStorage.setItem('order_id', resultdata.order_id);
            }
            /* if(resultdata.application_user === true) {
            navigate("/user/"+resultdata.user_slug,{state:resultdata.result.uid});
            } else { */
              const drupal_domain_url = configData.DRUPAL_DOMAIN_URL;
              window.location.href = drupal_domain_url+"/user_auto_login?user_session="+resultdata.user_session;
            /* } */
          }
        }
    }
  };
  return (
    <div className='login-form-container'>
    <div id="login-form-smww">
      
      <div className="region region-content">
        <div id="block-system-main" className="block block-system">
          <div className="login-content">
          <h2 className="element-invisible">Primary tabs</h2>
          <ul class="tabs primarya">
        <li className="active">
          <a href="/user" className="active">
            Log in<span className="element-invisible">(active tab)</span>
          </a>
        </li>
        <li>
          <a href="/user/password">Request new password</a>
        </li>
      </ul>
      {console.log(Object.keys(errors).length)}
      {Object.keys(errors).length > 0 && (
          <div className="set-message-error">
           {Object.keys(errors).map((item, index) => {
           return <> <div><img src={domain_url+'/images/message-error.png'}  alt="error" /><span dangerouslySetInnerHTML={{__html: errors[item]}}></span></div></>
          
        })}  
          </div>
        )}
            <form action="/user" method="post" id="user-login" acceptCharset="UTF-8">
              <div>
             
                <div className="form-item form-type-textfield form-item-name">
                  <div className="input-container">
                    <label htmlFor="edit-name">
                      Username or Email Address{" "}
                      <span className="form-required" title="This field is required.">
                        *
                      </span>
                    </label>
                    <input tabIndex={1} type="text" id="edit-name" name="username" defaultValue="" size={60} maxLength={60} className="form-text required" onChange={handleChange}/>
                    <div className="description">Enter your SMWW Username or Email Address.</div>
                    {errors.username && <span className="error">{errors.username}</span>}
                  </div>
                </div>
                <div className="form-item form-type-password form-item-pass">
                  <div className="input-container">
                    <label htmlFor="edit-pass">
                      Password{" "}
                      <span className="form-required" title="This field is required.">
                        *
                      </span>
                    </label>
                    <input tabIndex={1} type="password" id="edit-pass" name="password" size={60} maxLength={128} className="form-text required" onChange={handleChange} />
                    <div className="description">Enter the password that accompanies your Username or Email Address.</div>
                   
                  </div>
                </div>
                <div className="form-item form-type-checkbox form-item-remember-me">
                  <input tabIndex={1} type="checkbox" id="edit-remember-me" name="rememberMe" defaultValue={1} defaultChecked={true} className="form-checkbox" />{" "}
                  <label className="option" htmlFor="edit-remember-me">Remember me</label>
                </div>
                <input type="hidden" name="form_build_id" defaultValue="form-jm5NRo6iFJE4oCqDmJXl1vtLV4y-qcJhWVxWHrg7e1g" />
                <input type="hidden" name="form_id" defaultValue="user_login" />
                <div className="form-actions form-wrapper" id="edit-0">
                  <input type="submit" id="edit-submit" name="op" value="Log in" className="form-submit" onClick={handleLogin} />
                </div>
                <a className="form-submit login-form-submit" href="/user/password">Don't know your password?</a>
                <a href="https://www.smwwearena.com">To Log In To Your eArena Account, Please Click Here</a>
                <div className="messages warning">
                  <p>
                    To complete your course registration or pay for a course, please login above and click "Register" in the right column after logging in.
                  </p>
                  <p>
                    If you are unable to login here, please navigate to the course you would like to register for and submit a new application. You will be able to complete your registration and pay at the end of the process, and at that time your new user account will be created. You may do this even if you have applied in the past.
                  </p>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
      {/*left*/}
      <div className="left"></div>
      {/* EOF: #left */}
      {/*center*/}
      <div className="center left-center">
        <div className="region region-content">
          <div id="block-system-main" className="block block-system">
            <div className="content">
              <div id="node-523" className="node node-page" about="/home_page" typeof="foaf:Document">
                <div className="content clearfix"></div>
              </div>
            </div>
          </div>
          <div className="clearfix">
            <div className="links"></div>
          </div>
        </div>
      </div>
    </div>
  </div>
  );
}

export default Login;
