import React, { useState, useEffect,useRef} from 'react';
import { configData } from "../config";
import { Link, useNavigate, useSearchParams } from 'react-router-dom';
import MinicartLength from './MinicartLength';
import ReactDOM from "react-dom/client";
import Loading from './Loading';

function PaypalPaymentNotify() {
    const[searchParams,setSearchParams] = useSearchParams();
    const order_id = searchParams.get("order_id");
    const payer_id = searchParams.get("PayerID");
    const app_url = configData.APP_URL;
    const navigate = useNavigate();
   
    useEffect(()=>{
        PaypalSuccessNotify(order_id, payer_id);
    },[payer_id])
    return (<Loading />);
    async function PaypalSuccessNotify(order_id, payer_id) {
        const postData = {
          order_id: order_id,
          PayerID: payer_id
          }
       const response = await fetch(app_url+'smwwcommercepayment/notifypaypalpayment', {
              method: 'POST',
              body: JSON.stringify(postData),
              headers: {
                'Content-Type': 'application/json',
              },
              });
              const data = await response.json();
              if(data.data.result === "Success") {
                const order_id = localStorage.getItem('order_id');
                const drupal_orderid = localStorage.getItem('drupal_orderid');
                localStorage.removeItem("order_id");
                localStorage.removeItem("drupal_orderid");
                 const root = ReactDOM.createRoot(document.getElementById("top-header-cart-icon"));
                 root.render(<MinicartLength length={0} />);
                    navigate("/smww/checkout/thankyou/"+drupal_orderid);
              } else {
               
              }
      }

}

export default PaypalPaymentNotify;