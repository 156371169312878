import React from 'react'
import './classes/OrderDetailsPrint.css';
import { useState, useEffect, useRef} from 'react';
import { configData } from "../config";
import ReactToPrint from 'react-to-print'
import OrderDetailsPrint from './OrderDetailsPrint';
import OrderDetailsSend from './OrderDetailsSend';

function OrderPage  (data)  {
   const result = data.data;
   const printRef = useRef();
   const sendemailRef = useRef();
   const app_url = configData.APP_URL;
   const [isPrintModalOpen, setIsPrintModalOpen] = useState(false);
   const [isSendReceiptModalOpen, setIsSendReceiptModalOpen] = useState(false);
   const[success,setSuccess] = useState({});
   const[order_id, setOrderid] = useState({});
   const[uid, setUid] = useState({});
   const loggedin_uid = localStorage.getItem('uid');
   const handlePrint = (order_id,uid) => {
    window.scrollTo(0, 0);
    setIsPrintModalOpen(true);
    setOrderid(order_id);
    setUid(uid);
  };
  const handleSend = (order_id,uid) => {
    window.scrollTo(0, 0);
    setIsSendReceiptModalOpen(true);
    setOrderid(order_id);
    setUid(uid);
  };
  const handleCloseModal = () => {
    setIsPrintModalOpen(false);
    setIsSendReceiptModalOpen(false); 
  };
  const handleSendEmail = async event => { 
    
    const postData = {
        'order_id': order_id,
        'uid':uid
    }
    const response = await fetch(app_url+'smwwreceipt/sendreceiptemail', {
        method: 'POST',
        body: JSON.stringify(postData),
        headers: {
          'Content-Type': 'application/json',
        },
        });
        const data = await response.json();
        if(data.data.msg === "Success") {
            setIsSendReceiptModalOpen(false);
            setSuccess(data.data.result);
            window.scrollTo(0, 0);
        }
  };
  return (
    <>
    <div class="page-view-myorder">
    {success.length > 0 && (
          <>
           {success.map((item, index) => {
             return <>
            <div className="set-message-done">
            <span className="cart-message-icon message-icon"><i className="fa fa-check-circle"></i></span> {item}
          </div></>
          
        })}  
          </>
        )}
        {loggedin_uid && !Number.isNaN(loggedin_uid) ? (
            <>
        <div class="panel panel-default">
            <div class="panel-heading" style={{background:'#fefefe'}}>
                <b>Courses</b>
            </div>
            <div class="panel-body">
                <table class="table table-bordered table-striped">
                    <thead>
                        <tr>
                            <th width="12%">Order Number</th>
                            <th width="30%">Description</th>
                            <th width="12%">Paid By</th>
                            <th width="15%">Paid On</th>
                            <th width="12%" style={{textAlign:'right'}}>Amount Paid</th>
                            <th width="12%">Status</th><th width="15%"></th>
                        </tr>
                    </thead>
                    <tbody>
                        {result.course && (
                            <>
                        {result.course.map((item, index) => (
                        <tr>
                            <td><a style={{color: "#2C99D2"}} href={"/user/"+item.uid+"/smww_orders/"+item.order_id}>
                            {item.drupal_orderid ? ( item.drupal_orderid ) :( item.order_id ) }</a>
                            </td>
                            <td>{item.product_title}</td>
                            <td>{item.paidby}</td>
                            <td>{item.updated_at}</td>
                            <td style={{ textAlign: 'right' }}>${item.calculated_amount}</td>
                            <td>{item.status}</td>
                            <td>
                                <a><img src={`${process.env.PUBLIC_URL}/images/send_email_icon.jpg`} className='order-details-pnt-img' alt="email"  onClick={() => handleSend(item.order_id,item.uid)}/></a>
                                <br />
                                <a><img src={`${process.env.PUBLIC_URL}/images/print_icon.png`} className='order-details-pnt-img' alt="print" width="20px;"onClick={() => handlePrint(item.order_id,item.uid)} /></a>
                            </td>
                        </tr>
                        ))} 
                        </>
                        )}
                    </tbody>
                </table>     
            </div>
        </div>

<div class="panel panel-default">
    <div class="panel-heading" style={{background:'#fefefe'}}>
         <b>Payment Plans</b>
     </div>
     <div class="panel-body">
     <table class="table table-bordered table-striped">
    <thead>
        <tr>
       
            <th width="10%">Order Number</th>
            <th width="20%">Description</th>
            <th width="12%">Paid By</th>
            <th width="12%">Due Date</th>
            <th width="10%" style={{ textAlign: 'right' }}>Amount</th>
            <th width="10%">Paid On</th>
            <th width="10%">Status</th>
            <th width="10%">Subscription</th>
            <th width="20%"></th>
        </tr>
    </thead>
    <tbody>
    {result.course_subscription && (
        <>
        {result.course_subscription.map((item, index) => (
            <tr>
                <td><a style={{color: "#2C99D2"}} href={"/user/"+item.uid+"/smww_orders/"+item.order_id}>
                    {item.drupal_orderid ? ( item.drupal_orderid ) :( item.order_id ) }</a>
                </td>
                <td>{item.product_title}</td>
                <td>{item.paidby}</td>
                <td>{item.due_date}</td>
                <td style={{ textAlign: 'right' }}>${item.amount}</td>
                <td>{item.paid_on}</td>
                <td>{item.status} <br/>
                    {item.changecard_link && (
                    <a href={item.changecard_link} className='user-pp-cup'>Change Card</a>
                    )}
                </td>
                <td>{item.sub_status} <br/>
                    {item.update_link && (
                    <a href={item.update_link} className='user-pp-cup'>Update</a>
                    )}
                </td>
                <td>
                    <a><img src={`${process.env.PUBLIC_URL}/images/send_email_icon.jpg`} className='order-details-pnt-img' alt="email" onClick={() => handleSend(item.order_id,item.uid)}/></a>
                    <br />
                    <a><img src={`${process.env.PUBLIC_URL}/images/print_icon.png`} className='order-details-pnt-img' alt="print" onClick={() => handlePrint(item.order_id,item.uid)}/></a>
                </td>
            </tr>
        ))} 
        </>
    )}
       
       
    </tbody>
    </table>
     
    </div>
</div>
  
  
<div class="panel panel-default">
    <div class="panel-heading" style={{background:'#fefefe'}}>
         <b>Cancelled Payments</b>
     </div>
     <div class="panel-body">
    <table class="table table-bordered table-striped">
    <thead>
        <tr>
            <th width="10%">Order Number</th>
            <th width="20%">Description</th>
            <th width="12%">Paid By</th>
            <th width="12%">Due Date</th>
            <th width="10%" style={{textAlign:'right'}}>Amount</th>
            <th width="10%">Paid On</th><th width="10%">Status</th>
            <th width="10%">Subscription</th>
            </tr>
            </thead>
            <tbody>
            {result.cancelled_subscription && (
                <>
                {result.cancelled_subscription.map((item, index) => (
                    <tr>
                    <td><a style={{color: "#2C99D2"}} href={"/user/"+item.uid+"/smww_orders/"+item.order_id}>
                    {item.drupal_orderid ? ( item.drupal_orderid ) :( item.order_id ) }</a>
                    </td>
                    <td>{item.product_title}</td>
                    <td>{item.paidby}</td>
                    <td>{item.due_date}</td>
                    <td style={{ textAlign: 'right' }}>${item.amount}</td>
                    <td>{item.paid_on}</td>
                    <td>{item.status} <br/>
                    {item.changecard_link && (
                    <a href={item.changecard_link} className='user-pp-cup'>Change Card</a>
                    )}
                    </td>
                    <td className="ss_red">{item.sub_status} <br/>
                    {item.update_link && (
                    <a href={item.update_link} className='user-pp-cup'>Change Due Date</a>
                    )}
                    </td>
                    </tr>
                ))} 
                 </>
            )}
            </tbody>
        </table>
    </div>
</div>
  
<div class="panel panel-default">
    <div class="panel-heading" style={{background:'#fefefe'}}>
         <b>Miscellaneous Charge</b>
     </div>
     <div class="panel-body">
<table class="table table-bordered table-striped">
    <thead>
        <tr>
            <th width="15%">Order Number</th>
            <th width="30%">Description</th>
            <th width="13%">Paid By</th>
            <th width="12%">Paid On</th>
            <th width="12%" style={{textAlign:'right'}}>Amount Paid</th>
            <th width="12%">Status</th>
            <th width="15%"></th>
            </tr>
            </thead>
            <tbody>
            {result.misc ? (  
                            <>
                        {result.misc.map((item, index) => (
                        <tr>
                            <td><a style={{color: "#2C99D2"}} href={"/user/"+item.uid+"/smww_orders/"+item.order_id}>
                            {item.drupal_orderid ? ( item.drupal_orderid ) :( item.order_id ) }</a>
                            </td>
                            <td>{item.product_title}</td>
                            <td>{item.paidby}</td>
                            <td>{item.updated_at}</td>
                            <td style={{ textAlign: 'right' }}>${item.calculated_amount}</td>
                            <td>{item.status}</td>
                            <td>
                                <a><img src={`${process.env.PUBLIC_URL}/images/send_email_icon.jpg`} className='order-details-pnt-img' alt="email" onClick={() => handleSend(item.order_id,item.uid)}/></a>
                                <br />
                                <a><img src={`${process.env.PUBLIC_URL}/images/print_icon.png`} className='order-details-pnt-img' alt="print" onClick={() => handlePrint(item.order_id,item.uid)}/></a>
                            </td>
                        </tr>
                        ))} 
                        </>
                        ):(
                        <><tr><td colspan="7" style={{textAlign:'center'}}>No Data Found</td></tr></>
                        )}
                    </tbody>
                    </table> 
            </div>
</div>
  
  

<div class="panel panel-default">
    <div class="panel-heading" style={{background:'#fefefe'}}>
         <b>Conferences</b>
     </div>
     <div class="panel-body">
        <table class="table table-bordered table-striped">
            <thead>
                <tr>
                    <th width="12%">Order Number</th>
                    <th width="30%">Description</th>
                    <th width="12%">Paid By</th>
                    <th width="15%">Paid On</th>
                    <th width="13%" style={{textAlign:'right'}}>Amount Paid</th>
                    <th width="12%">Status</th><th width="15%"></th>
                </tr>
            </thead>
            <tbody>
            {result.conf ? (  
                            <>
                        {result.conf.map((item, index) => (
                        <tr>
                            <td><a style={{color: "#2C99D2"}} href={"/user/"+item.uid+"/smww_orders/"+item.order_id}>{item.order_id}</a></td>
                            <td>{item.product_title}</td>
                            <td>{item.paidby}</td>
                            <td>{item.updated_at}</td>
                            <td style={{ textAlign: 'right' }}>${item.calculated_amount}</td>
                            <td>{item.status}</td>
                            <td>
                                <a><img src={`${process.env.PUBLIC_URL}/images/send_email_icon.jpg`} className='order-details-pnt-img' alt="email" onClick={() => handleSend(item.order_id,item.uid)}/></a>
                                <br />
                                <a><img src={`${process.env.PUBLIC_URL}/images/print_icon.png`} className='order-details-pnt-img' alt="print" onClick={() => handlePrint(item.order_id,item.uid)}/></a>
                            </td>
                        </tr>
                        ))} 
                        </>
                        ):(
                        <><tr><td colspan="7" style={{textAlign:'center'}}>No Data Found</td></tr></>
                        )}
            </tbody>
        </table>
    </div>
</div>


<div class="panel panel-default">
    <div class="panel-heading" style={{background:'#fefefe'}}>
         <b>Fees</b>
     </div>
     <div class="panel-body">
        <table class="table table-bordered table-striped"><thead><tr><th width="15%">Order Number</th><th width="40%">Description</th><th width="15%">Paid By</th><th width="15%">Paid On</th><th width="15%" style={{textAlign:'right'}}>Amount Paid</th><th width="15%">Status</th></tr></thead><tbody><tr><td colspan="6" style={{textAlign:'center'}}>No Data Found</td></tr></tbody></table>     </div>
</div>
  
  

<div class="panel panel-default">
    <div class="panel-heading" style={{background:'#fefefe'}}>
         <b>Re-Enrolls</b>
     </div>
     <div class="panel-body">
     <table class="table table-bordered table-striped">
            <thead>
                <tr>
                    <th width="12%">Order Number</th>
                    <th width="30%">Description</th>
                    <th width="12%">Paid By</th>
                    <th width="15%">Paid On</th>
                    <th width="13%" style={{textAlign:'right'}}>Amount Paid</th>
                    <th width="12%">Status</th><th width="15%"></th>
                </tr>
            </thead>
            <tbody>
            {result.reenroll ? (  
                            <>
                        {result.reenroll.map((item, index) => (
                        <tr>
                            <td><a style={{color: "#2C99D2"}} href={"/user/"+item.uid+"/smww_orders/"+item.order_id}>{item.order_id}</a></td>
                            <td>{item.product_title} <br /> {item.classroom_name}</td>
                            <td>{item.paidby}</td>
                            <td>{item.updated_at}</td>
                            <td style={{ textAlign: 'right' }}>${item.calculated_amount}</td>
                            <td>{item.status}</td>
                            <td>
                                <a><img src={`${process.env.PUBLIC_URL}/images/send_email_icon.jpg`} className='order-details-pnt-img' alt="email" onClick={() => handleSend(item.order_id,item.uid)}/></a>
                                <br />
                                <a><img src={`${process.env.PUBLIC_URL}/images/print_icon.png`} className='order-details-pnt-img' alt="print" onClick={() => handlePrint(item.order_id,item.uid)}/></a>
                            </td>
                        </tr>
                        ))} 
                        </>
                        ):(
                        <><tr><td colspan="7" style={{textAlign:'center'}}>No Data Found</td></tr></>
                        )}
            </tbody>
        </table>
</div>
</div>




<div class="panel panel-default">
    <div class="panel-heading" style={{background:'#fefefe'}}>
         <b>Agent Initiation</b>
     </div>
     <div class="panel-body">
     <table class="table table-bordered table-striped">
            <thead>
                <tr>
                    <th width="12%">Order Number</th>
                    <th width="30%">Description</th>
                    <th width="12%">Paid By</th>
                    <th width="15%">Paid On</th>
                    <th width="13%" style={{textAlign:'right'}}>Amount Paid</th>
                    <th width="12%">Status</th><th width="15%"></th>
                </tr>
            </thead>
            <tbody>
            {result.agent_initiation ? (  
                            <>
                        {result.agent_initiation.map((item, index) => (
                        <tr>
                            <td><a style={{color: "#2C99D2"}} href={"/user/"+item.uid+"/smww_orders/"+item.order_id}>{item.order_id}</a></td>
                            <td>{item.product_title} <br /> {item.classroom_name}</td>
                            <td>{item.paidby}</td>
                            <td>{item.updated_at}</td>
                            <td style={{ textAlign: 'right' }}>${item.calculated_amount}</td>
                            <td>{item.status}</td>
                            <td>
                                <a><img src={`${process.env.PUBLIC_URL}/images/send_email_icon.jpg`} className='order-details-pnt-img' alt="email" onClick={() => handleSend(item.order_id,item.uid)}/></a>
                                <br />
                                <a><img src={`${process.env.PUBLIC_URL}/images/print_icon.png`} className='order-details-pnt-img' alt="print" onClick={() => handlePrint(item.order_id,item.uid)}/></a>
                            </td>
                        </tr>
                        ))} 
                        </>
                        ):(
                            
                        <><tr><td colspan="7" style={{textAlign:'center'}}>No Data Found</td></tr></>
                        )}
            </tbody>
        </table>
</div>
</div>





<div class="panel panel-default">
    <div class="panel-heading" style={{background:'#fefefe'}}>
         <b>Agent Initiation Subscription</b>
     </div>
     <div class="panel-body">
     <table class="table table-bordered table-striped">
    <thead>
        <tr>
       
            <th width="10%">Order Number</th>
            <th width="20%">Description</th>
            <th width="12%">Paid By</th>
            <th width="12%">Due Date</th>
            <th width="10%" style={{ textAlign: 'right' }}>Amount</th>
            <th width="10%">Paid On</th>
            <th width="10%">Status</th>
            <th width="10%">Subscription</th>
            <th width="20%"></th>
        </tr>
    </thead>
    <tbody>
    {result.agent_subscription && (
        <>
        {result.agent_subscription.map((item, index) => (
            <tr>
                <td><a style={{color: "#2C99D2"}} href={"/user/"+item.uid+"/smww_orders/"+item.order_id}>{item.order_id}</a></td>
                <td>{item.product_title}</td>
                <td>{item.paidby}</td>
                <td>{item.due_date}</td>
                <td style={{ textAlign: 'right' }}>${item.amount}</td>
                <td>{item.paid_on}</td>
                <td>{item.status} <br/>
                    {item.changecard_link && (
                    <a className='user-pp-cup'>Change Card</a>
                    )}
                </td>
                <td>{item.sub_status} <br/>
                    {item.update_link && (
                    <a className='user-pp-cup'>Update</a>
                    )}
                </td>
                <td>
                    <a><img src={`${process.env.PUBLIC_URL}/images/send_email_icon.jpg`} className='order-details-pnt-img' alt="email" onClick={() => handleSend(item.order_id,item.uid)}/></a>
                    <br />
                    <a><img src={`${process.env.PUBLIC_URL}/images/print_icon.png`} className='order-details-pnt-img' alt="print" onClick={() => handlePrint(item.order_id,item.uid)}/></a>
                </td>
            </tr>
        ))} 
        </>
    )}
       
       
    </tbody>
    </table>
    </div>
</div>






<div class="panel panel-default">
    <div class="panel-heading" style={{background:'#fefefe'}}>
         <b>Scout Initiation</b>
     </div>
     <div class="panel-body">
     <table class="table table-bordered table-striped">
            <thead>
                <tr>
                    <th width="12%">Order Number</th>
                    <th width="30%">Description</th>
                    <th width="12%">Paid By</th>
                    <th width="15%">Paid On</th>
                    <th width="13%" style={{textAlign:'right'}}>Amount Paid</th>
                    <th width="12%">Status</th><th width="15%"></th>
                </tr>
            </thead>
            <tbody>
            {result.scout_initiation ? (  
                            <>
                        {result.scout_initiation.map((item, index) => (
                        <tr>
                            <td><a style={{color: "#2C99D2"}} href={"/user/"+item.uid+"/smww_orders/"+item.order_id}>{item.order_id}</a></td>
                            <td>{item.product_title} <br /> {item.classroom_name}</td>
                            <td>{item.paidby}</td>
                            <td>{item.updated_at}</td>
                            <td style={{ textAlign: 'right' }}>${item.calculated_amount}</td>
                            <td>{item.status}</td>
                            <td>
                                <a><img src={`${process.env.PUBLIC_URL}/images/send_email_icon.jpg`} className='order-details-pnt-img' alt="email" onClick={() => handleSend(item.order_id,item.uid)}/></a>
                                <br />
                                <a><img src={`${process.env.PUBLIC_URL}/images/print_icon.png`} className='order-details-pnt-img' alt="print" onClick={() => handlePrint(item.order_id,item.uid)}/></a>
                            </td>
                        </tr>
                        ))} 
                        </>
                        ):(
                            
                        <><tr>{console.log("Hai")}<td colspan="7" style={{textAlign:'center'}}>No Data Found</td></tr></>
                        )}
            </tbody>
        </table>
</div>
</div>


<div class="panel panel-default">
    <div class="panel-heading" style={{background:'#fefefe'}}>
         <b>Scout Initiation Subscription</b>
     </div>
     <div class="panel-body">
     <table class="table table-bordered table-striped">
    <thead>
        <tr>
       
            <th width="10%">Order Number</th>
            <th width="20%">Description</th>
            <th width="12%">Paid By</th>
            <th width="12%">Due Date</th>
            <th width="10%" style={{ textAlign: 'right' }}>Amount</th>
            <th width="10%">Paid On</th>
            <th width="10%">Status</th>
            <th width="10%">Subscription</th>
            <th width="20%"></th>
        </tr>
    </thead>
    <tbody>
    {result.scout_subscription && (
        <>
        {result.scout_subscription.map((item, index) => (
            <tr>
                <td><a style={{color: "#2C99D2"}} href={"/user/"+item.uid+"/smww_orders/"+item.order_id}>{item.order_id}</a></td>
                <td>{item.product_title}</td>
                <td>{item.paidby}</td>
                <td>{item.due_date}</td>
                <td style={{ textAlign: 'right' }}>${item.amount}</td>
                <td>{item.paid_on}</td>
                <td>{item.status} <br/>
                    {item.changecard_link && (
                    <a className='user-pp-cup'>Change Card</a>
                    )}
                </td>
                <td>{item.sub_status} <br/>
                    {item.update_link && (
                    <a className='user-pp-cup'>Update</a>
                    )}
                </td>
                <td>
                    <a><img src={`${process.env.PUBLIC_URL}/images/send_email_icon.jpg`} className='order-details-pnt-img' alt="email" onClick={() => handleSend(item.order_id,item.uid)}/></a>
                    <br />
                    <a><img src={`${process.env.PUBLIC_URL}/images/print_icon.png`} className='order-details-pnt-img' alt="print" onClick={() => handlePrint(item.order_id,item.uid)}/></a>
                </td>
            </tr>
        ))} 
        </>
    )}
       
       
    </tbody>
    </table>
    </div>
</div>

<div class="panel panel-default">
    <div class="panel-heading" style={{background:'#fefefe'}}>
         <b>Apparel Store</b>
     </div>
     <div class="panel-body">
<table class="table table-bordered table-striped">
    <thead>
        <tr>
            <th width="15%">Order Number</th>
            <th width="30%">Description</th>
            <th width="13%">Paid By</th>
            <th width="12%">Paid On</th>
            <th width="12%" style={{textAlign:'right'}}>Amount Paid</th>
            <th width="12%">Status</th><th width="15%"></th>
            </tr>
            </thead>
            <tbody>
            {result.apparel ? (  
                            <>
                        {result.apparel.map((item, index) => (
                        <tr>
                            <td><a style={{color: "#2C99D2"}} href={"/user/"+item.uid+"/smww_orders/"+item.order_id}>{item.order_id}</a></td>
                            <td>{item.product_title} <br /> {item.classroom_name}</td>
                            <td>{item.paidby}</td>
                            <td>{item.updated_at}</td>
                            <td style={{ textAlign: 'right' }}>${item.calculated_amount}</td>
                            <td>{item.status}</td>
                            <td>
                                <a><img src={`${process.env.PUBLIC_URL}/images/send_email_icon.jpg`} className='order-details-pnt-img' alt="email" onClick={() => handleSend(item.order_id,item.uid)}/></a>
                                <br />
                                <a><img src={`${process.env.PUBLIC_URL}/images/print_icon.png`} className='order-details-pnt-img' alt="print" onClick={() => handlePrint(item.order_id,item.uid)}/></a>
                            </td>
                        </tr>
                        ))} 
                        </>
                        ):(
                            
                        <><tr><td colspan="7" style={{textAlign:'center'}}>No Data Found</td></tr></>
                        )}
                    </tbody>
                    </table>
                    </div>
                    </div>
                    {isPrintModalOpen && (
                         <><div className="order-details-print-modal-overlay"></div>
        <div className="order-details-print-modal">
          <div className="order-details-print-modal-content" ref={printRef}>
          <div className='order-details-print-header'>
            <div className='receipt-heading' style={{fontSize: '15.4px'}}>Print Receipt</div>
            <a onClick={handleCloseModal} className="printpage-close-button">x</a>
          </div>
             <div className='order-det-print-button'>
              <ReactToPrint
                trigger={() => <button className="print-button">Print</button>}
                content={() => printRef.current}
              />
            </div>
            <div id="order-details-print">
              <OrderDetailsPrint order_id={order_id} uid={uid} />
            </div>
          </div>
        </div></>
      )}
        {isSendReceiptModalOpen && (
        <><div className="order-details-print-modal-overlay"></div>
        <div className="order-details-print-modal">
          <div className="order-details-print-modal-content">
            <div className='order-details-print-header'>
            <div className='receipt-heading' style={{fontSize: '15.4px'}}>Send Receipt</div>
              <a onClick={handleCloseModal} className="printpage-close-button">×</a>
            </div>
            <div className='order-det-print-button'>
              <button className="print-button" onClick={handleSendEmail}>Send email</button>
            </div>
            <div id="order-details-print" ref={sendemailRef}>
              <OrderDetailsSend order_id={order_id} uid={uid}/>
            </div>
          </div>
        </div></>
      )}</>
    ):(
        <><p>You are not authorized.</p></>
    )}
</div>
    
    
    
    </>
  )
}

export default OrderPage